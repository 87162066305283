import React from "react";
import { IconName } from "components/ui/Icon";
import { ApiUserProfile } from "utils/api/user";

interface Item {
  icon: IconName;
  title: string;
  body: React.ReactNode;
}

const BASE_ITEMS: Item[] = [
  {
    icon: "calendar",
    title: "Upcoming bills",
    body: "To promote financial health, your spending limit will decrease if it looks like your available bank balance is not enough to cover your upcoming bills.",
  },
  {
    icon: "briefcase",
    title: "Your payroll provider",
    body: "Reset relies on data from your payroll provider. If a shift hasn’t posted 24 hours after you worked, try contacting your employer.",
  },
];

export const getItems = (userProfile: ApiUserProfile): Item[] => {
  const items = [];

  if (!userProfile.currentCardAccount) {
    items.push({
      icon: "userCircle",
      title: "New user account",
      body: "If you just signed up for Reset, it may take up to an hour to process your data and determine your spending limit.",
    });
  } else {
    items.push({
      icon: "shuffle",
      title: "Direct deposit switching",
      body: "100% of your direct deposit must flow through Reset currently. At this time, Reset cannot support cardholders who choose to split their direct deposit.",
    });
  }

  items.push(...BASE_ITEMS);

  if (userProfile.currentCardAccount) {
    items.push({
      icon: "grocery",
      title: "Card spend",
      body: "If none of the above is true, you may have spent your entire limit! Access new earnings daily as you continue to work.",
    });
  }

  return items;
};
